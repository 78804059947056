<template>
  <b-card title="الايميلات">
    <b-tabs>
      <b-tab>
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>كل الايميلات </span>
        </template>

       <AllMails></AllMails>
      </b-tab>
      <!-- <b-tab active>
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Add Category</span>
        </template>

       <AddCategory></AddCategory>
      </b-tab> -->
     
     
    </b-tabs>

  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BTabs, BTab, BCardText,BCard } from 'bootstrap-vue'

import AllMails from './AllMails.vue';


export default {
  components: {
    BCardCode,
    BCard,
    BCardText,
    BTabs,
    BTab,
    AllMails,
    
},
  data() {
    return {
   
    }
  },
}
</script>
